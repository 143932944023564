<template>
  <div class="login-page">
    <div class="login-panel">
      <transition name="fade-in-slow">
        <div class="login-box" v-show="mostrarLogo">
          <div class="logo" style="text-align: center;">
            <div>
              <img src="../../assets/logo-secco.png" style="height: 50px;" />
            </div>
            <h4 style="color: #ffffff">Relevamiento</h4>
          </div>
        </div>
      </transition>
      <transition name="fade-in-slow">
        <el-form
            v-show="mostrarForm"
            :model="form"
            :rules="rules"
            ref="form"
            style="margin: auto; width: 95%;"
        >
          <el-form-item prop="email">
            <el-input
                ref="email"
                class="input-login"
                placeholder="Email"
                v-model="form.email"
                :autofocus="true"
                maxlength="100"
                @keyup.enter.native="forgotPassword"
            ></el-input>
          </el-form-item>
          <el-form-item style="margin-bottom: 0px">
            <el-button
                style="width: 100%;"
                type="primary"
                @click="forgotPassword"
                size="medium"
                v-loading="cargando">Enviar
            </el-button>
          </el-form-item>
          <el-form-item style="margin-bottom: 0px">
            <el-button
                style="width: 100%; color: white; margin-left: 0px; margin-top: 10px;"
                type="text"
                @click="$router.push('/login')"
                size="small">Volver
            </el-button>
          </el-form-item>
        </el-form>
      </transition>
    </div>
  </div>
</template>

<style>
.input-login > input {
  background-color: rgba(255, 255, 255, 0) !important;
  border-radius: 0px;
  border-bottom: 2px solid #dcdfe6 !important;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  color: #fff;
}

.login-page {
  height: 100%;
  width: 100%;
  display: flex;
}

.login-panel {
  background: #ffffff14;
  height: 450px;
  min-width: 300px;
  max-width: 300px;
  margin: auto;
  padding: 30px;
  z-index: 2;
  border-radius: 10px;
}

.login-panel .login-box .logo {
  margin-bottom: 60px;
}

.login-panel .login-box .logo > h1 {
  text-align: center;
  color: #ffffff;
}

.imagen-fondo {
  position: absolute;
  left: 30%;
  top: 30%;
  z-index: 1;
  opacity: 0.2;
}

.fade-in-slow-enter-active,
.fade-in-slow-leave-active {
  transition: opacity 2s;
}

.fade-in-slow-enter,
.fade-in-slow-leave-to {
  opacity: 0;
}
</style>

<script>
import { AUTH_REQUEST } from "../../store/actions/auth";

export default {
  name: "login",
  data() {
    return {
      form: {
        email: ""
      },

      mostrarLogo: false,
      mostrarForm: false,
      cargando: false
    };
  },
  computed: {
    rules() {
      let rules = {
        email: [
          {
            required: true,
            message: "Por favor introduzca su correo.",
            trigger: "change"
          },
          {
            type: "email",
            message: "El correo no es válido.",
            trigger: "change"
          }
        ]
      };

      return rules;
    }
  },
  created() {
    setTimeout(() => {
      this.mostrarLogo = true;
      setTimeout(() => {
        this.mostrarForm = true;
        setTimeout(() => {
          this.$refs.email.focus()
        }, 200);
      }, 300);
    }, 50);
  },
  methods: {
    forgotPassword: function() {
      this.$refs.form.validate((respuesta, data) => {
        if (respuesta) {
          let params = {
            email: this.form.email
          };

          this.cargando = true;

          this.$api
            .post(this.$api.URL + "/auth/forgotPassword", params)
            .then(respuesta => {
              this.postRealizado(respuesta);
            });
        }
      });
    },

    postRealizado(respuesta) {
      this.cargando = false;
      if (respuesta.estado != 1) {
        if (respuesta.estado == -2) {
          this.$message({
            message: "El email ingresado no existe.",
            type: "error"
          });
        } else {
          this.$message({
            message: "Ocurrió un error. Reintente.",
            type: "error"
          });
        }
        return;
      }

      this.$message({
        message: "Correo enviado. Revise su casilla de correo.",
        type: "success",
        showClose: true
      });

      this.$router.push("/login");
    }
  },
  beforeCreate: function() {
    document.body.className = "body_sinlayout";
  },
  beforeDestroy: function() {
    document.body.className = "";
  }
};
</script>
